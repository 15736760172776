.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-family: "Red Hat Display", sans-serif;
  color: #f3f5f9;
  background-image: url("../src/img/about3.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  opacity: 0.9;
}

.App-header {
  //width: 100vw;
  height: auto;

  box-shadow: 2px 2px 3px rgb(58, 60, 71);
  display: flex;
  flex-direction: row;
  // .logo-column {
  //   width: 50%;
  //   .logo {
  //     width: 25%;
  //   }
  // }

  // background-color: #a1acbf;
  background: linear-gradient(
    90deg,
    rgb(197, 197, 205) 35%,
    rgb(89, 90, 95) 65%
  );

  // rgb(101, 101, 121) 35%,
  // rgb(58, 60, 71) 65%
  display: flex;
  flex-direction: row;
  // align-items: flex-start;
  justify-content: flex-end;
  // font-size: calc(10px + 2vmin);
  color: white;
  @media (max-width: 1100px) {
    width: 100%;
    // flex-direction: row;
  }
  @media (max-width: 700px) {
    font-size: 15px;
    width: 100%;
    // flex-direction: row;
  }
  @media (max-width: 400px) {
    font-size: 13px;
    width: 100%;
    // flex-direction: row;
  }
}
.logo-column {
  width: 50%;
  .logo {
    width: 15%;
    margin-top: -70px;
  }
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  // margin: 50px 50px 0 50px;
  margin-left: 500px;
  width: 50%;
  @media (max-width: 1100px) {
    width: 100%;
    flex-direction: row;
    margin-left: 4px;
  }
  // @media (max-width: 700px) {
  //   width: 100%;
  //   flex-direction: row;
  //   margin-left: 2px;
  // }
  @media (max-width: 700px) {
    display: none;
  }
  .nav-link {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 16px;
    @media (max-width: 700px) {
      background-color: #eddbe0;
      flex-direction: row;
      flex-wrap: wrap;
      z-index: 10;
      height: auto;
    }
  }
}

.navigation-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 700px) {
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    &.inactive {
      display: none;
    }
    &.show {
      display: flex;
    }
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    color: white;
    margin-right: 20px;
    height: 70px;

    cursor: pointer;
  }
  a:hover {
    font-weight: bolder;
  }
}
.about-title {
  // padding-top: 50px;
  text-transform: capitalize;

  h1 {
    position: relative;
  }
}

.about-title {
  h1::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 1;
    background: linear-gradient(
      90deg,
      rgb(101, 101, 121) 35%,
      rgb(58, 60, 71) 65%
    );
    z-index: -1;
    // left: 46%;
    top: 10%;
    transform: translateX(-50%);
  }
}

.about {
  width: 800px;
  display: flex;
  flex-direction: row;
  color: #f3f5f9;
  font-weight: bold;
  // color: #42526c;
  margin: 50px calc(40% - 400px);
  @media (max-width: 1100px) {
    width: 600px;
    margin: 0 calc(50% - 300px);
  }
  @media (max-width: 700px) {
    width: 400px;
    // font-size: 15px;
    margin: 0 calc(50% - 200px);
  }
  @media (max-width: 400px) {
    flex-direction: column;
    width: 100%;

    // font-size: 15px;
    //margin: 50px;
  }
  p {
    width: 500px;
    font-size: 30px;
    margin-left: 45px;
    position: relative;
    // background-color: rgb(165, 156, 207);
    text-align: left;
    // padding: 30px;
    color: #f3f5f9;
    background-color: #4f5763;
    opacity: 0.7;
    box-shadow: 3px 4px 4px 3px rgb(58, 60, 71);
    @media (max-width: 400px) {
      width: 300px;
    }
  }

  img {
    width: 200px;
    height: 250px;
    // box-shadow: 5px 5px 3px rgb(198, 201, 231);
    // rotate: -12deg;
    margin-right: 15px;

    border: 2px solid rgb(232, 220, 241);
    @media (max-width: 800px) {
      width: 200px;
      height: 200px;
      rotate: -12deg;
    }
    @media (max-width: 400px) {
      rotate: 0deg;
      margin-left: calc(50% - 70px);
    }
  }
}
.project-title,
.game-title {
  color: #4f5763;
  background-color: #f5f6f8;
  width: 300px;
  margin-left: calc(50% - 150px);
  opacity: 0.7;
  //position: relative;
  //padding-bottom: 70px;
}
// .project-title::after,
// .game-title::after {
//   content: "";
//   position: absolute;
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   // opacity: 1;
//   background: linear-gradient(90deg, rgb(81 81 86) 35%, rgb(126 128 150) 65%);

//   z-index: -1;
//   // left: 580px;
//   top: 15px;
//   transform: translateX(-50%);
// }
.typewriter {
  width: 300px;
  font-size: 22px;
  flex-direction: column;
  color: #f5f0f0;
  //font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  // border-right: 0.15em solid rgb(64, 45, 10); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 calc(50% - 150px); /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(30, end) alternate,
    blink-caret 0.5s step-end infinite;
  background-color: #4f5763;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.typewriterHello {
  width: 180px;
  font-size: 22px;
  flex-direction: column;
  color: #f1efef;
  //font-family: monospace;
  //overflow: hidden; /* Ensures the content is not revealed until the animation */
  //border-right: 0.15em solid rgb(64, 45, 10); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 calc(50% - 90px); /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typingHello 3.5s steps(30, end) alternate,
    blink-caret 0.3s step-end;
  background-color: #4f5763;

  opacity: 0.4;
}

/* The typing effect */
@keyframes typingHello {
  from {
    width: 0;
  }
  to {
    width: 20%;
  }
}
.projects,
.game-container {
  background: linear-gradient(90deg, rgb(197 197 205) 35%, rgb(89 90 95) 65%);

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 5px 5px 3px rgb(40, 43, 73);
  //   margin: 20px calc(50% - 400px);
  padding: 50px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    flex-direction: column;

    //padding: 50px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    //padding: 50px;
  }

  div {
    background-color: rgb(204, 213, 223);
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    text-align: center;

    height: 150px;
    width: 150px;
    cursor: pointer;
    box-shadow: 2px 3px 3px 2px rgb(56, 52, 95);
    // border-radius: 50%;

    @media (max-width: 900px) {
      height: 100px;
      width: 200px;
      margin: 10px 10px;
    }
    @media (max-width: 600px) {
      height: 100px;
      width: 250px;
      // margin: 10px;
      margin: 10px calc(50% - 125px);
    }
    @media (max-width: 450px) {
      height: 100px;
      width: 250px;
      // margin-bottom: 50px;
    }
    h4 {
      width: 140px;
      color: #f2f0f5;
      background-color: #433c4e;
      border-radius: 10px;
      margin: 50px calc(50% - 70px);
      @media (max-width: 900px) {
        width: 80px;
        margin: 30px calc(50% - 40px);
      }
      @media (max-width: 600px) {
        width: 120px;
        margin: 30px calc(50% - 60px);
      }
    }

    a {
      color: #42526c;
      font-weight: bold;
      text-decoration: none;
      text-align: center;

      @media (max-width: 900px) {
        font-size: 13px;
      }
      @media (max-width: 600px) {
        font-size: 15px;
        padding: 5px;
      }
    }
  }
  .projectImg {
    background-image: url(../src/img/form.png);
  }
  .recepiesImg {
    background-image: url(../src/img/recepiesImg.png);
  }
  .countriesImg {
    background-image: url(../src/img/countriesImg.png);
  }
  .filmsImg {
    background-image: url(../src/img/filmsImg.png);
  }
  .quizImg {
    background-image: url(../src/img/quizImg.png);
  }
  .BallImg {
    background-image: url(../src/img/BallImg.png);
  }
  .ticImg {
    background-image: url(../src/img/ticImg.png);
  }
  .ticCompImg {
    background-image: url(../src/img/ticCompImg.png);
  }
  .gameImg {
    background-image: url(../src/img/gameImg.png);
  }
  div:hover {
    background-color: rgb(87, 84, 118);
    height: 150px;
    width: 150px;
    border-radius: 50%;
    transition: all 0.7s ease 0s;
    opacity: 1;
    @media (max-width: 900px) {
      height: 100px;
      width: 200px;
      //margin: 10px;
      border-radius: 50%;
    }
    @media (max-width: 600px) {
      height: 100px;
      width: 250px;
      //margin: 10px;
      border-radius: 0;
    }
    @media (max-width: 450px) {
      height: 100px;
      width: 250px;
      // margin: 10px;
      border-radius: 0;
    }
    a {
      color: white;
      padding-top: 40px;
    }
  }
  a:hover {
    color: #f3f5f9;
  }
}

.cv-img img {
  width: 700px;
  @media (max-width: 1200px) {
    width: 100%;
  }
}
.cv {
  padding: 20px;
}

.hamburger {
  display: none;
  @media (max-width: 700px) {
    display: fixed;
    width: 2rem;
    height: 1.3rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
  }
}
.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: #080211;
  transform-origin: 1px;
  transition: all 0.3s linear;
}
