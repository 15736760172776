form {
  width: 400px;
  margin: 20px calc(50% - 200px);
  display: flex;
  flex-direction: column;

  @media (max-width: 375px) {
    width: 80%;

    padding: 0;
    margin-left: 10%;
  }
  input {
    flex-direction: row;
    border-radius: 15px;
    margin: 10px;
    padding: 10px;
    font-size: 18px;
    border: 1px solid rgb(234, 228, 228);
    background-color: rgb(192, 192, 198);
    box-shadow: 2px 3px 3px rgb(55, 51, 73);
    @media (max-width: 375px) {
      font-size: 15px;
    }
  }
  ::-webkit-input-placeholder {
    font-size: 18px;
    padding: 10px;
    font-style: italic;
  }
  textarea {
    border-radius: 15px;
    margin: 10px;
    padding: 10px;
    font-size: 18px;
    background-color: rgb(192, 192, 198);
    border: 1px solid rgb(234, 228, 228);
    box-shadow: 2px 3px 3px rgb(55, 51, 73);
  }

  .btn-send {
    padding: 5px 10px;
    border-radius: 15px;
    border: 1px solid rgb(99, 101, 110);
    cursor: pointer;
    background-color: rgb(101, 105, 117);
    color: white;
    box-shadow: 2px 3px 3px rgb(55, 51, 73);
  }
  .btn-send:hover {
    background-color: rgb(181, 183, 190);
    color: rgb(78, 76, 76);
  }
}

.contact-options {
  width: 400px;
  margin: 20px calc(50% - 200px);
  @media (max-width: 375px) {
    width: 90%;

    padding: 0;
    margin-left: 5%;
  }
}

article {
  width: 380px;
  margin: 20px calc(50% - 190px);
  border-radius: 15px;
  background-color: rgb(75, 75, 78);
  box-shadow: 2px 3px 3px rgb(55, 51, 73);
  margin: 10px;

  h4 {
    padding: 10px 0 0 0;
    font-weight: bolder;
  }

  h5 {
    color: rgb(212, 197, 223);
  }
  @media (max-width: 375px) {
    width: 80%;
    padding: 0;
    margin-left: 10%;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: rgb(150, 151, 163);
  font-weight: bolder;
}
a:hover {
  color: white;
}
